import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiSpeakers = createApi({
    reducerPath: 'apiSpeakers',
    baseQuery: fetchBaseQuery({ baseUrl: config.apiURL }),
    tagTypes: ['Speakers'],
    endpoints: (builder) => ({
        getSpeakers: builder.query({
            query: () => '/speakers',
            providesTags: ['Speakers'],
        }),
        getOneSpeaker: builder.query({
            query: (id) => `/speakers/${id}`,
            providesTags: ['Speakers'],
        }),
        createSpeaker: builder.mutation({
            query: (item) => {
                const { token, body } = item;
                return {
                    url: '/speakers',
                    method: 'POST',
                    headers: {
                        auth: `Bearer ${JSON.parse(token)}`,
                    },
                    body: body,
                };
            },
            invalidatesTags: ['Speakers'],
        }),
        updateSpeaker: builder.mutation({
            query: (item) => {
                const { id, token, body } = item;
                return {
                    url: `/speakers/${id}`,
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        auth: `Bearer ${JSON.parse(token)}`,
                    },
                    body: JSON.stringify(body),
                };
            },
            invalidatesTags: ['Speakers'],
        }),
        deleteSpeaker: builder.mutation({
            query: (item) => ({
                url: `/speakers/${item.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    auth: `Bearer ${JSON.parse(item.token)}`,
                },
                method: 'DELETE',
            }),
            invalidatesTags: ['Speakers'],
        }),
    }),
});

export const {
    useGetSpeakersQuery,
    useGetOneSpeakerQuery,
    useCreateSpeakerMutation,
    useDeleteSpeakerMutation,
    useUpdateSpeakerMutation,
} = apiSpeakers;
