import { Icon } from '../../common/iconSprite/IconSprite';
import { HashLink } from 'react-router-hash-link';

// import Map from '../map/Map';
import logo from '../../../assets/images/head_2024.png';
import './footer.scss';
const Footer = () => {
    return (
        <footer id="contacts" className="footer">
            <div className="footer__container">
                <div className="footer__body body-footer">
                    <div className="body-footer__column body-footer__column_logo">
                        <a href="/" className="body-footer__logo-ibg">
                            <img src={logo} alt="#" />
                        </a>
                        <h2 className="body-footer__title-logo">
                            <span>FUTURE OF SCIENCE</span> IS CREATED BY WHAT WE
                            DO TODAY
                        </h2>
                        <a
                            className="body-footer__link body-footer__link_mail"
                            href="mailto:iscsai.org@gmail.com"
                        >
                            iscsai.org@gmail.com
                        </a>
                    </div>
                    <div className="body-footer__column body-footer__column_one">
                        <h3 className="body-footer__title">Menu</h3>
                        <ul className="body-footer__list">
                            <li className="body-footer__item">
                                <HashLink
                                    to="/#about"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    About
                                </HashLink>
                            </li>
                            {/* <li className="body-footer__item">
                                <HashLink
                                    to="/#agenda"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    Agenda
                                </HashLink>
                            </li> */}
                            <li className="body-footer__item">
                                <HashLink
                                    to="/#code"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    E-certificate
                                </HashLink>
                            </li>
                            <li className="body-footer__item">
                                <HashLink
                                    to="/#speakers"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    Speakers
                                </HashLink>
                            </li>
                            <li className="body-footer__item">
                                <HashLink
                                    to="/#committees"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    Committees & Submission
                                </HashLink>
                            </li>
                            <li className="body-footer__item">
                                <HashLink
                                    to="/#dates"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    Dates & News
                                </HashLink>
                            </li>
                            <li className="body-footer__item">
                                <HashLink
                                    to="/#partners"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    Partners
                                </HashLink>
                            </li>
                            <li className="body-footer__item">
                                <HashLink
                                    to="/#gallery"
                                    smooth={true}
                                    duration={500}
                                    className="body-footer__link"
                                >
                                    Background
                                </HashLink>
                            </li>
                        </ul>
                    </div>

                    <div className="body-footer__column body-footer__column_two">
                        <h3 className="body-footer__title">
                            Previous congresses
                        </h3>
                        <ul className="body-footer__list body-footer__list_years">
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1gzzpnazmlbIWID7pODac17R7lu3fK9RF/view"
                                    className="body-footer__link"
                                >
                                    2023
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1LHKX0ivv0H8ZnB8H7q4j6xMBgEJopCsD/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2022
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1kWC_KPFCSWIb585a55Wg9Eds4fJmpzH0/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2021
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1FJUoTWoTq3FtyLlm4dftzHKYuRSBp4uj/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2020
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1NRy8D7834pgVLgkB8C2bW5LWIFGSCGfP/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2019
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1m1wal7TKmgguGYfqc1C9w-5gpactr5Bf/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2018
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1iOCA6kmuMoHuJF_qgNPcKyIBl46unXtb/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2017
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1z9f2XaDFbevTEfYsNaouIxfVMqK6fNWP/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2016
                                </a>
                            </li>
                            <li className="body-footer__item">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://drive.google.com/file/d/1UMkWgB-p6UozRSRJxVurE9SDLPVv4Wo9/view?usp=sharing"
                                    className="body-footer__link"
                                >
                                    2015
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* <div className="body-footer__column body-footer__column_map">
            <div className="body-footer__map">
               <Map /> 
              <div className="footer-map">

                <iframe
                  title="Google Maps"
                  src="https://www.google.com/maps/d/embed?mid=1yG8v3mj6CCkghTQNJS0bslCAO4lJvUOl&ehbc=2E312F"
                  // width="640"
                  // height="480"
                ></iframe>
              </div>
            </div>
          </div> */}
                </div>
                <ul className="follow">
                    <li className="follow__item">
                        <p className="follow__info">Follow us</p>
                    </li>
                    <li className="follow__item">
                        <a
                            href="/"
                            className="follow__link"
                            aria-label="facebook"
                        >
                            <Icon name="mail" className="icon__mail" />
                        </a>
                    </li>
                    <li className="follow__item">
                        <a
                            href="/"
                            className="follow__link"
                            aria-label="facebook"
                        >
                            <Icon name="facebook" className="icon__facebook" />
                        </a>
                    </li>
                </ul>
                <div className="footer__copyright copyright">
                    <h3 className="copyright__title">
                        © 2023 | <a href="/">isc-sai.org</a>
                    </h3>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
