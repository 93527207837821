// import { useState, useRef, Children, useEffect } from "react";
import { useRef } from 'react';
import Slider from 'react-slick';
// import "slick-carousel/slick/slick.scss";
// import "slick-carousel/slick/slick-theme.scss";
import './swiper.scss';

const Swiper = ({ children, cardsLength }) => {
    // const [activeIndex, setActiveIndex] = useState(0);
    const sliderRef = useRef(null);
    // const [slidesToShow, setSlidesToShow] = useState(3);

    // const CustomDots = ({ activeIndex, slidesToShow, children, sliderRef }) => {
    // 	const slides = Children.toArray(children);
    // 	const numDots = Math.ceil(slides.length);

    // 	const dotElements = [];

    // 	for (let i = 0; i < numDots; i++) {
    // 		const isActive = i === activeIndex / slidesToShow;
    // 		dotElements.push(
    // 			<button
    // 				key={i}
    // 				className={`dot ${isActive ? 'active' : ''}`}
    // 				onClick={() => sliderRef.current.slickGoTo(i * slidesToShow)}
    // 			/>
    // 		);
    // 	}

    // 	return (
    // 		<div className="custom-dots-container">
    // 			{dotElements}
    // 		</div>
    // 	);
    // };

    // const CustomPrevArrow = ({ onClick }) => {
    // 	return (
    // 		<button onClick={onClick} type='button' className="slide-control__button slide-control__button_prev">
    // 			<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    // 				<path d="M14.9698 7.00004C14.9698 7.44368 14.6096 7.80395 14.1659 7.80395H2.80913L7.25222 12.0428C7.57448 12.3493 7.586 12.8601 7.27942 13.1792C6.97286 13.5004 6.46446 13.5119 6.14304 13.2064L0.250178 7.58138C0.0902173 7.43196 0.000244141 7.22102 0.000244141 7.00004C0.000244141 6.77906 0.0902174 6.57147 0.249258 6.41745L6.14211 0.792453C6.46204 0.488101 6.97096 0.499484 7.279 0.820913C7.58556 1.13899 7.57405 1.6496 7.2518 1.9573L2.80872 6.19614H14.196C14.6384 6.19647 14.9698 6.55808 14.9698 7.00004Z" fill="#119DA4" />
    // 			</svg>
    // 		</button>
    // 	);
    // }

    // const CustomNextArrow = ({ onClick }) => {
    // 	return (
    // 		<button onClick={onClick} type='button' className="slide-control__button slide-control__button_next" >
    // <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    // 	<path d="M14.9698 7.00004C14.9698 7.44368 14.6096 7.80395 14.1659 7.80395H2.80913L7.25222 12.0428C7.57448 12.3493 7.586 12.8601 7.27942 13.1792C6.97286 13.5004 6.46446 13.5119 6.14304 13.2064L0.250178 7.58138C0.0902173 7.43196 0.000244141 7.22102 0.000244141 7.00004C0.000244141 6.77906 0.0902174 6.57147 0.249258 6.41745L6.14211 0.792453C6.46204 0.488101 6.97096 0.499484 7.279 0.820913C7.58556 1.13899 7.57405 1.6496 7.2518 1.9573L2.80872 6.19614H14.196C14.6384 6.19647 14.9698 6.55808 14.9698 7.00004Z" fill="#119DA4" />
    // </svg>
    // 		</button >
    // 	);
    // }

    const params = {
        adaptiveHeight: false,
        dots: true,
        // infinite: cardsLength > 3 ? true : false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        lazyLoad: true,
        // prevArrow: <CustomPrevArrow />,
        // nextArrow: <CustomNextArrow />,
        // appendDots: dots => (
        // 	<CustomDots
        // 		activeIndex={activeIndex}
        // 		slidesToShow={slidesToShow}
        // 		sliderRef={sliderRef}
        // 	>
        // 		{dots}
        // 	</CustomDots>
        // ),
        // beforeChange: (oldIndex, newIndex) => {
        // 	setActiveIndex(newIndex);
        // },
        responsive: [
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // useEffect(() => {
    // 	const handleResize = () => {
    // 		if (window.innerWidth >= 992) {
    // 			setSlidesToShow(3);
    // 		} else if (window.innerWidth < 992 && window.innerWidth > 768) {
    // 			setSlidesToShow(2);
    // 		} else {
    // 			setSlidesToShow(1);
    // 		}
    // 	};
    // 	handleResize();
    // 	window.addEventListener('resize', handleResize);
    // 	return () => {
    // 		window.removeEventListener('resize', handleResize);
    // 	};
    // }, [slidesToShow]);

    return (
        <Slider {...params} ref={sliderRef}>
            {children}
        </Slider>
    );
};

export default Swiper;
