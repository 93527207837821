import Footer from "./landing/footer/Footer";
import Header from "./landing/header/Header";
import HeaderMenu from "./landing/headerMenu/HeaderMenu";
import Modal from "./landing/modal/Modal";
import PrivateRoute from "./common/privateRoute/PrivateRoute";
import ScrollToTop from "./common/scrollToTop/ScrollToTop";
import Spinner from "./common/spinner/Spinner";
import Swiper from "./landing/swiper/Swiper";
import ScrollingContent from "./landing/scrollingContent/ScrollingContent";



export {
	Footer,
	Header,
	HeaderMenu,
	Modal,
	PrivateRoute,
	ScrollToTop,
	Spinner,
	Swiper,
	ScrollingContent
}