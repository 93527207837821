import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiAgenda = createApi({
    reducerPath: 'apiAgenda',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiURL,
    }),
    tagTypes: ['Agenda'],
    endpoints: (builder) => ({
        getAgenda: builder.query({
            query: () => '/agenda',
            providesTags: ['Agenda'],
        }),
        getOneAgenda: builder.query({
            query: (id) => `/agenda/${id}`,
            providesTags: ['Agenda'],
        }),
        // createPdftemplate: builder.mutation({
        //     query: (pdftemplates) => ({
        //         url: '/agenda',
        //         method: 'POST',
        //         body: pdftemplates,
        //     }),
        //     invalidatesTags: ['Agenda'],
        // }),
        // updatePdftemplate: builder.mutation({
        //     query: (item) => {
        //         const { id, body, token } = item;
        //         return {
        //             url: `/agenda/${id}`,
        //             method: 'PUT',
        //             headers: {
        //                 auth: `Bearer ${JSON.parse(token)}`,
        //             },
        //             body: body,
        //         };
        //     },
        //     invalidatesTags: ['Agenda'],
        // }),
        // deletePdftemplate: builder.mutation({
        //     query: (id) => ({
        //         url: `/agenda/${id}`,
        //         method: 'DELETE',
        //     }),
        //     invalidatesTags: ['Agenda'],
        // }),
    }),
});

export const { useGetAgendaQuery, useGetOneAgendaQuery } = apiAgenda;
