import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from './config';

export const apiVerifyToken = createApi({
  reducerPath: 'apiAuth',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiURL }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    verifyToken: builder.mutation({
      query: (token) => ({
        url: '/verifyToken',
        method: 'POST',
        body: { token: JSON.parse(token) },
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const { useVerifyTokenMutation } = apiVerifyToken;
