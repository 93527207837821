import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from './config';

export const apiWelcome = createApi({
  reducerPath: 'apiWelcome',
  baseQuery: fetchBaseQuery({ baseUrl: `${config.apiURL}/welcome` }),
  tagTypes: [
    'WelcomeButtons',
    'Subheading',
    'Tag',
    'Description',
    'BackgroundImage',
    'MainImage',
  ],
  endpoints: (builder) => ({
    getButtons: builder.query({
      query: () => '/buttons',
      providesTags: ['WelcomeButtons'],
    }),
    getOneButton: builder.query({
      query: (id) => `/buttons/${id}`,
      providesTags: ['WelcomeButtons'],
    }),
    createButton: builder.mutation({
      query: (heading) => ({
        url: '/buttons',
        method: 'POST',
        body: heading,
      }),
      invalidatesTags: ['WelcomeButtons'],
    }),
    updateButton: builder.mutation({
      query: (heading) => {
        const { id, body } = heading;
        return {
          url: `/buttons/${id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: ['WelcomeButtons'],
    }),
    deleteButton: builder.mutation({
      query: (id) => ({
        url: `/buttons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WelcomeButtons'],
    }),
    // ! subheading
    getSubheading: builder.query({
      query: () => '/subheading',
      providesTags: ['Subheading'],
    }),

    updateSubheading: builder.mutation({
      query: (subheading) => {
        const { token, body } = subheading;
        return {
          url: `/subheading`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Subheading'],
    }),

    // ! tag
    getTag: builder.query({
      query: () => '/tag',
      providesTags: ['Tag'],
    }),

    updateTag: builder.mutation({
      query: (tag) => {
        const { token, body } = tag;
        return {
          url: `/tag`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Tag'],
    }),

    // ! description
    getDescription: builder.query({
      query: () => '/description',
      providesTags: ['Description'],
    }),

    updateDescription: builder.mutation({
      query: (desc) => {
        const { token, body } = desc;
        return {
          url: `/description`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Description'],
    }),

    // ! backgroundImage
    getBackgroundImage: builder.query({
      query: () => '/backgroundImage',
      providesTags: ['BackgroundImage'],
    }),

    updateBackgroundImage: builder.mutation({
      query: (item) => {
        const { token, body } = item;
        return {
          url: `/backgroundImage`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['BackgroundImage'],
    }),

    // ! mainImage
    getMainImage: builder.query({
      query: () => '/mainImage',
      providesTags: ['MainImage'],
    }),

    updateMainImage: builder.mutation({
      query: (item) => {
        const { token, body } = item;
        return {
          url: `/mainImage`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['MainImage'],
    }),
  }),
});

export const {
  useGetButtonsQuery,
  useGetOneButtonQuery,
  useCreateButtonMutation,
  useDeleteButtonMutation,
  useUpdateButtonMutation,
  useGetSubheadingQuery,
  useUpdateSubheadingMutation,
  useGetTagQuery,
  useUpdateTagMutation,
  useGetDescriptionQuery,
  useUpdateDescriptionMutation,
  useGetBackgroundImageQuery,
  useUpdateBackgroundImageMutation,
  useGetMainImageQuery,
  useUpdateMainImageMutation,
} = apiWelcome;
