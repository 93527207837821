// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrolling-content {
  display: flex;
  gap: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/landing/scrollingContent/scrollingContent.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,SAAA;AACD","sourcesContent":[".scrolling-content {\r\n\tdisplay: flex;\r\n\tgap: 25px;\r\n\t// will-change: transform;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
