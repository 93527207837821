import { AboutPageAsync } from './about/AboutPage.async';
import { BackgroundPageAsync } from './background/BackgroundPage.async';
import { CommitteePageAsync } from './committee/CommitteePage.async';
import { DiagramsPageAsync } from './diagrams/DiagramsPage.async';
import { FilesPageAsync } from './files/FilesPage.async';

import { LoginAsync } from './login/Login.async';
import { NewsPageAsync } from './news/NewsPage.async';
import { ParticipantsPageAsync } from './participants/ParticipantsPage.async';
import { SpeakersPageAsync } from './speakers/SpeakersPage.async';
import { TimerPageAsync } from './timer/TimerPage.async';
import { WelcomePageAsync } from './welcomePage/WelcomePage.async';
import { PDFConstructorPageAsync } from './pdfconstructor/PDFConstructor.async';

// import WelcomePage from './welcomePage/WelcomePage';
export {
    LoginAsync as Login,
    DiagramsPageAsync as DiagramsPage,
    WelcomePageAsync as WelcomePage,
    FilesPageAsync as FilesPage,
    TimerPageAsync as TimerPage,
    CommitteePageAsync as CommitteePage,
    AboutPageAsync as AboutPage,
    NewsPageAsync as NewsPage,
    ParticipantsPageAsync as ParticipantsPage,
    BackgroundPageAsync as BackgroundPage,
    SpeakersPageAsync as SpeakersPage,
    PDFConstructorPageAsync as PDFConstructorPage,
};
