import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "./config";

export const apiLogin = createApi({
	reducerPath: 'apiLogin',
	baseQuery: fetchBaseQuery({ baseUrl: config.apiURL }),
	tagTypes: ['Login'],
	endpoints: builder => ({
		authLogin: builder.mutation({
			query: (user) => ({
				url: '/login',
				method: 'POST',
				body: { ...user },
			}),
			invalidatesTags: ['Login']
		}),
	})
});


export const { useAuthLoginMutation, } = apiLogin;