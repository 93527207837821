import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiBackground = createApi({
    reducerPath: 'apiBackground',
    baseQuery: fetchBaseQuery({ baseUrl: config.apiURL }),
    tagTypes: ['Background'],
    endpoints: (builder) => ({
        getBackgrounds: builder.query({
            query: () => '/background',
            providesTags: ['Background'],
        }),
        getBackground: builder.query({
            query: (id) => `/background/${id}`,
            providesTags: ['Background'],
        }),
        createBackground: builder.mutation({
            query: (body) => ({
                url: '/background',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Background'],
        }),
        updateBackground: builder.mutation({
            query: (item) => {
                const { id, body } = item;
                return {
                    url: `/background/${id}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: ['Background'],
        }),
        deleteBackground: builder.mutation({
            query: (id) => ({
                url: `/buttons/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Background'],
        }),
    }),
});

export const {
    useGetBackgroundsQuery,
    useCreateBackgroundMutation,
    useGetBackgroundQuery,
    useUpdateBackgroundMutation,
    useDeleteBackgroundMutation,
} = apiBackground;
