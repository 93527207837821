import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiParticipants = createApi({
    reducerPath: 'apiParticipants',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiURL,
    }),
    tagTypes: ['Participants'],
    endpoints: (builder) => ({
        getParticipants: builder.query({
            query: () => '/participants',
            providesTags: ['Participants'],
        }),
        getParticipant: builder.query({
            query: (id) => `/participants/${id}`,
            providesTags: ['Participants'],
        }),
        createParticipant: builder.mutation({
            query: (item) => {
                const { body, token } = item;
                return {
                    url: '/participants',
                    method: 'POST',
                    headers: {
                        auth: `Bearer ${JSON.parse(token)}`,
                    },
                    body: body,
                };
            },
            invalidatesTags: ['Participants'],
        }),
        updateParticipant: builder.mutation({
            query: (item) => {
                const { id, body, token } = item;
                return {
                    url: `/participants/${id}`,
                    method: 'PUT',
                    headers: {
                        auth: `Bearer ${JSON.parse(token)}`,
                    },
                    body: body,
                };
            },
            invalidatesTags: ['Participants'],
        }),
        deleteParticipant: builder.mutation({
            query: (item) => {
                const { id, token } = item;
                return {
                    url: `/participants/${id}`,
                    headers: {
                        auth: `Bearer ${JSON.parse(token)}`,
                    },
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['Participants'],
        }),
    }),
});

export const {
    useGetParticipantsQuery,
    useGetParticipantQuery,
    useDeleteParticipantMutation,
    useCreateParticipantMutation,
    useUpdateParticipantMutation,
} = apiParticipants;
