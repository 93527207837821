import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiNews = createApi({
  reducerPath: 'apiNews',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiURL }),
  tagTypes: ['News'],
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => '/news',
      providesTags: ['News'],
    }),
    getOneNews: builder.query({
      query: (id) => `/news/${id}`,
      providesTags: ['News'],
    }),
    createNews: builder.mutation({
      query: (item) => {
        const { token, body } = item;
        return {
          url: '/news',
          method: 'POST',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['News'],
    }),
    updateNews: builder.mutation({
      query: (item) => {
        const { id, token, body } = item;
        return {
          url: `/news/${id}`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['News'],
    }),
    deleteNews: builder.mutation({
      query: (item) => {
        const { id, token } = item;
        return {
          url: `/news/${id}`,
          method: 'DELETE',
          headers: {
            auth: `Bearer ${token.replaceAll('"', '')}`,
          }
        }
      },
      invalidatesTags: ['News'],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetOneNewsQuery,
  useCreateNewsMutation,
  useDeleteNewsMutation,
  useUpdateNewsMutation,
} = apiNews;
